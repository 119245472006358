@mixin card-input-styles {
  flex: 1;
  height: 2.5rem;
  border: 1px solid #000000;
  padding: 0 0.7rem;
  color: #000000;
  border-radius: 8px;
  border: rgb(177, 177, 177) 1px solid;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &::placeholder {
    color: #000000;
    opacity: 0.4;
    font-size: 0.8rem;
  }
}

.payment-section {
  * {
    margin: 0;
    padding: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .result-section {
    .result-container {
      .result-content {
        .success-message {
          background: rgb(255, 255, 255);
          padding: 20px 50px 50px 50px;
          border-radius: 4px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          display: inline-block;
          margin: 0 auto;
          h1 {
            color: #88b04b;
            font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
            font-weight: 900;
            font-size: 40px;
            margin-bottom: 10px;
          }
          p {
            color: #404f5e;
            font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
            font-size: 20px;
            margin: 0;
          }
          .success-icon {
            margin-left: 110px;
            margin-bottom: 50px;
            i {
              color: #9abc66;
              font-size: 100px;
              height: 200px;
              width: 200px;
            }
          }
          .failure {
            text-align: center;
            width: 350px;
            h1 {
              color: #ff1f1fd8;
              font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
              font-weight: 900;
              font-size: 35px;
              margin-bottom: 10px;
            }
            p {
              color: #404f5e;
              font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
              font-size: 20px;
              margin: 0;
            }
            .failure-icon {
              i {
                color: #ff1f1fd8;
                font-size: 150px;
              }
            }
          }
        }
      }
    }
  }
  .payment-form {
    h3 {
      font-size: 1.8rem;
      color: #000000;
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
    }
    .form-container {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 1px 2px 8px 0px #c8c8c8;
      padding: 30px;
      border-radius: 10px;
      .form-content {
        form {
          display: flex;
          flex-direction: column;

          .form-group {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;
            input {
              @include card-input-styles;
            }
            select {
              @include card-input-styles;
            }
          }
        }

        .pay-button {
          width: 100%;
          height: 2.5rem;
          padding: 0 0.7rem;
          border-radius: 6px;
          border: none;
          background-color: #3bc7ff;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 600;
          margin-top: 10px;
          justify-content: center;
          align-items: center;
          display: flex;
          cursor: pointer;
          &:hover {
            background-color: #49ccff;
          }
          &:disabled {
            background-color: #49cbff84;
          }

          span{
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

hr {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgb(118 118 118 / 75%),
    rgba(0, 0, 0, 0)
  );
  margin: 5px 0 15px 0;
}

@media only screen and (max-width: 480px) {
  .form-container {
    box-shadow: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }
}
