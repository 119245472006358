.sfp {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefef;
  font-family: serif;
  flex-direction: column;

  h3{
    margin-left: 16px;
  }
}
