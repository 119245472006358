.threedsv {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefef;
  font-family: serif;

  @mixin flex-center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .container {
    width: 340px;
    height: 380px;
    box-shadow: 1px 1px 4px rgb(201, 201, 201);
    border-radius: 6px;
    overflow: hidden;
    background: white;

    .header,
    .body {
      @include flex-center;
    }

    h3 {
      margin: 8px 0px 16px 0px;
    }

    .header {
      width: 100%;
      background: #1373d0;
      color: white;
    }

    .body {
      padding: 25px;
      p{
        margin-top: 0;
      }
      .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 4px 0;
        &.th{
          border-bottom: 1px solid #dfdfdf;
          margin: 6px 0;
          span{
            font-weight: 600;
          }
        }
        span{
          flex: 1;
          text-align: center;
        }
        &.Successful{
          color: green;
        }
        &.Failed{
          color: red;
        }
        &.Pending{
          color: rgb(255, 136, 0);
        }
      }
    }

    input,
    button {
      border-radius: 6px;
      width: 100%;
      height: 2.5rem;
      box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
    }

    input {
      background-color: #fff;
      border: 1px solid #b1b1b1;
      color: #000;
      padding: 0 8px;
      width: calc(100% - 16px);
      margin: 12px 0;
      margin-top: 24px;
    }

    button {
      border: none;
      background-color: #3bc7ff;
      color: #ffffff;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 10px;
      cursor: pointer;
      &:hover {
        background-color: #49ccff;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }
}
